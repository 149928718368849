/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import {AUTHENTICATION_STATES} from '../reducers/authentication.reducer';
import {connect} from 'react-redux';
import React from 'react';
import {Navigate} from 'react-router-dom';
import {LoadingIndicator} from '../ux/ux';
import t from '../i18n/locale-keys';
import withParams from './WithParams';
import { saveStorageItem, storageItems } from '../utils/local-storage';

const PrivateRoute = ({ authenticationStatus, children, signOutInProcess, location }) => {
    switch (authenticationStatus) {
        case AUTHENTICATION_STATES.LOGGED_IN:
            if (children) {
                return children;
            }
            break;
        case AUTHENTICATION_STATES.UNINITIALIZED:
            return <LoadingIndicator message={t.loading()} />;
        default:
            // Redirect logged-out users to the login page. We want to make it easy for them to access the current
            // page once they have logged in. If they logged out intentionally, they can do this via the browser
            // Back button. Otherwise we redirect them to the current page once they login.
            const isSSOLogout = window.location.pathname.indexOf('sso_logout') !== -1;
            if (!isSSOLogout) {
                if (signOutInProcess) {
                    return <Navigate push to="/login" />;
                }
                else {
                    // Set login_redirect in the session storage to allow redirect after external osp login
                    saveStorageItem(storageItems.POST_LOGIN_REDIRECT, location.pathname);
                    return (
                        <Navigate
                            to={{
                                pathname: '/login',
                                state: { referrer: location }
                            }}
                        />
                    );
                }
            }
    }
};


const mapStateToProps = ({
    authentication: { status },
    navigation: { signOutInProcess }
}) => ({
    authenticationStatus: status,
    signOutInProcess
});

export default withParams(connect(mapStateToProps)(PrivateRoute));
